import React, { useEffect, useState } from "react";
import { addDayInDate } from "utils/helperFunctions";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import {
  selectError,
  selectLoading,
  selectHotelList,
  selectAvailableFilterType,
} from ".././selectors";
import { sliceKey, reducer, homepageActions } from ".././slice";
import { hotelSearchListActions } from "../../searchHotels/slice";
import { homepageSaga } from ".././saga";
import { tabName } from ".././messageConfig";
import FlightComponent from "comp/SearchFlightComp";
import { Icon } from "@iconify/react";
import { useRouter } from "next/router";
import { encodeData } from "utils/encodeDecode";
import SearchHotelComponents from "comp/SearchHotelComp";
import { useTheme } from "src/contexts/themeContext";

interface HeroPageProps {
  defaultSelected: any;
  setCurrentTab: any;
  currentTab: any;
}
const imageUrls = [
  "/image/homepage/hotel_bg.jpg",
  "/image/homepage/flight_bgold.webp",
];

const HeroPage = ({
  setCurrentTab,
  currentTab,
  defaultSelected,
}: HeroPageProps) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: homepageSaga });
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoading: any = useSelector(selectLoading);
  const searchAvailableList: any = useSelector(selectHotelList);
  const availableFilterType = useSelector(selectAvailableFilterType);
  const initialGuest = { adult: 2, childAges: [], rooms: 1, children: 0 };
  const initialState = {
    flightData: {
      to: "",
      from: "",
      adults: 1,
      infants: 0,
      children: 0,
      cabin_type: "E",
      oneWay: true,
      totalPassengers: 9,
      onward: new Date(),
      showToList: false,
      showFromList: false,
      "return-date": addDayInDate(1, new Date()),
    },
    checkin: new Date(),
    guest: [initialGuest],
    deafultSelectedRoom: 0,
    showHotelList: false,
    showFromFlightList: false,
    showToFlightList: false,
    showGuestPopup: false,
    selected: defaultSelected || "hotel",
    checkout: addDayInDate(1, new Date()),
  };
  const [searchData, setsearchData] = React.useState<any>(initialState);
  const [errors, seterrors] = React.useState<any>({});
  const noResult = [
    { value: "Result not found", id: "", text: "Result not found" },
  ];
  const handleSelect = (value: string) => {
    setCurrentTab(value);
    setsearchData({ ...searchData, selected: value });
    document.cookie = `defaultSelected=${value};max-age=86400; path=/`;
    dispatch(homepageActions.clearHotelState());
  };

  const [loadedCounter, setLoadedCounter] = React.useState(0);

  React.useEffect(() => {
    setLoadedCounter(1);
  }, [typeof window !== "undefined"]);

  React.useEffect(() => {
    const selectedTab = localStorage.getItem("defaultSelected") || "";
    if (
      selectedTab &&
      ["flight", "hotel"].includes(selectedTab.toLowerCase()) &&
      loadedCounter !== 0
    ) {
      setsearchData({ ...searchData, selected: selectedTab });
    }
  }, [
    typeof window !== "undefined" && localStorage.getItem("defaultSelected"),
  ]);

  // const handleScroll = () => {
  //   const cryptoSection = document.getElementById("cryptoSection");
  //   const recentSearch = document.getElementById("recentSearch");

  //   if (recentSearch) {
  //     // cryptoSection.style.backgroundColor = 'red';
  //     cryptoSection.style.zIndex = "auto";
  //     cryptoSection.style.paddingTop = "60px";
  //   } else {
  //     // cryptoSection.style.paddingTop = '200px';
  //     // cryptoSection.style.zIndex = '-1';
  //   }
  // };
  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const handleshowFlightList = (val: boolean, showFlightList: string) => {
    setsearchData({
      ...searchData,
      [showFlightList]: val,
    });
  };
  const [isStart, setisStart] = React.useState(true);

  const handleDateChange = (
    name: string,
    date: any,
    alterOtherDate: string
  ) => {
    if (searchData[name] !== date) {
      const commonSetData = ["onward", "return-date"].includes(name)
        ? // ! for flight
          {
            ...searchData,
            flightData: {
              ...searchData.flightData,
              [name]: date,
              ...(alterOtherDate && {
                [alterOtherDate]: addDayInDate(1, date),
              }),
            },
          }
        : // ! for Hotel
          {
            ...searchData,
            [name]: date,
            ...(alterOtherDate &&
              isStart && {
                [alterOtherDate]: addDayInDate(1, date),
              }),
          };
      setsearchData(commonSetData);
    }
  };
  const handleGuestData = (
    name: string,
    index?: any,
    operation?: any,
    event?: any
  ) => {
    switch (name) {
      case "delete":
        const updatedArray = [
          ...searchData.guest.slice(0, index),
          ...searchData.guest.slice(index + 1, searchData.guest.length),
        ];
        setsearchData({
          ...searchData,
          guest: updatedArray,
          deafultSelectedRoom: updatedArray.length === 1 ? 0 : null,
        });
        break;
      case "edit" /* when user want to edit data */:
        setsearchData({ ...searchData, deafultSelectedRoom: index });
        break;
      case "adult": /*  when user increase or decrease the number from plus & minusß */
      case "children":
        let tempData = searchData.guest[index];
        let tempChildren = searchData.guest[index].childAges;
        tempData = {
          ...tempData,
          [name]:
            operation === "plus" ? tempData[name] + 1 : tempData[name] - 1,
          ...((name === "children" && {
            childAges:
              operation === "plus"
                ? [...tempChildren, 1]
                : tempChildren.slice(0, -1),
          }) ||
            []),
        };
        const updatedData = [
          ...searchData.guest.slice(0, index),
          tempData,
          ...searchData.guest.slice(index + 1, searchData.guest.length),
        ];
        setsearchData({ ...searchData, guest: updatedData });
        break;
      case "childAges" /* when user select children ages from dropdown */:
        const { value } = event.target;
        let tempChildAge =
          searchData.guest[searchData.deafultSelectedRoom].childAges[index];
        tempChildAge = +value;
        const updatedChildAge = [
          ...searchData.guest[searchData.deafultSelectedRoom].childAges.slice(
            0,
            index
          ),
          tempChildAge,
          ...searchData.guest[searchData.deafultSelectedRoom].childAges.slice(
            index + 1,
            searchData.guest[searchData.deafultSelectedRoom].childAges.length
          ),
        ];
        let modifiedData = searchData.guest[searchData.deafultSelectedRoom];
        modifiedData = {
          ...modifiedData,
          childAges: updatedChildAge,
        };
        setsearchData({
          ...searchData,
          guest: [
            ...searchData.guest.slice(0, searchData.deafultSelectedRoom),
            modifiedData,
            ...searchData.guest.slice(
              searchData.deafultSelectedRoom + 1,
              searchData.guest.length
            ),
          ],
        });
        break;
      default: // add
        setsearchData({
          ...searchData,
          guest: [
            ...searchData.guest,
            { ...initialGuest, rooms: searchData.guest.length },
          ],
          deafultSelectedRoom: searchData.guest.length,
        });
        break;
    }
  };
  const handleFlightChange = (event: any) => {
    const { name, value, text = "", data } = event.target;
    errors[name] && delete errors[name];
    switch (name) {
      case "oneWay":
        if (value === false && !searchData.flightData["return-date"]) {
          setsearchData({
            ...searchData,
            flightData: {
              ...searchData.flightData,
              [name]: value,
              "return-date": addDayInDate(
                1,
                new Date(searchData.flightData.onward)
              ),
            },
          });
        } else {
          setsearchData({
            ...searchData,
            flightData: {
              ...searchData.flightData,
              [name]: value,
            },
          });
        }
        break;
      case "cabin_type":
        setsearchData({
          ...searchData,
          flightData: {
            ...searchData.flightData,
            [name]: value,
          },
        });
        break;
      case "flight_from_id":

      case "flight_to_id":
        setsearchData({
          ...searchData,
          flightData: {
            ...searchData.flightData,
            ...((name === "flight_from_id" && {
              ["from"]: value,
              ["fromData"]: data,
              showFromList: false,
            }) ||
              {}),
            ...((name === "flight_to_id" && {
              ["to"]: value,
              ["toData"]: data,
              showToList: false,
            }) ||
              {}),
            [name]: value,
          },
        });
        break;
      default:
        setsearchData({
          ...searchData,
          flightData: {
            ...searchData.flightData,
            [name]: value,
            showToList: name === "to",
            showFromList: name === "from",
          },
        });
        if (value.length > 1) {
          dispatch(homepageActions.getHotelListReq({ type: "flight", value }));
        }

        break;
    }
  };
  const validation = () => {
    let err: any = {};
    if (searchData.selected === "flight") {
      const data = searchData.flightData;
      if (!data.flight_from_id) err.from = "Please fill the form";
      if (!data.flight_to_id) err.to = "Please fill the form";
    } else {
      if (!searchData.hotel_id) err.hotel = "Please Select One";
    }
    return err;
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const error: any = validation();
    seterrors(error);
    if (Object.keys(error).length === 0) {
      router.push({
        pathname: "/search-results/[slug]",
        query: { slug: encodeData(searchData) },
      });
    }
  };

  const toggleData = () => {
    setsearchData({
      ...searchData,
      flightData: {
        ...searchData.flightData,
        from: searchData.flightData.to,
        to: searchData.flightData.from,
      },
    });
  };

  const { darkMode } = useTheme();

  return (
    <>
      <section id="hero" className={` pb-0     `}>
        <div
          className={`h-full  pb-8 lg:pb-[100px] relative bg-holder  w-full    ${
            searchData.selected === "hotel"
              ? "!bg-[19%_30%] lg:!bg-[0%_30%]"
              : "lg:!bg-[0%_35%]"
          }  transition-all ease-in   `}
          style={{
            backgroundImage: `${
              darkMode
                ? "linear-gradient(to left , rgba(0,0,0,0.5), rgba(0,0,0,0.8))"
                : "linear-gradient(to left , rgba(0,0,0,0), rgba(0,0,0,0.6))"
            },url("${currentTab === "hotel" ? imageUrls[0] : imageUrls[1]}")`,
          }}
        >
          <div className=" container mx-auto">
            <div className="pt-[100px] lg:pt-[200px]">
              <h2
                className="text-white  font-semibold reveal mb-2 text-3xl lg:text-[40px] "
                style={{
                  textShadow: "rgba(0,0,0,0.3) 1px 0 10px",
                }}
              >
                Where do you want to{" "}
                {`${searchData.selected === "hotel" ? "stay" : "go "}`} ?
              </h2>
              <p
                className=" mb-12 text-gray-100"
                style={{
                  textShadow: "rgba(0,0,0,0.3) 1px 0 8px",
                }}
              >
                Search low prices on 2.3+ millions hotels and 450 flights.
              </p>

              <div className="relative  flex gap-2 text-sm  bg-white rounded-t-lg text-black  p-2 h-auto lg:w-[fit-content]  dark:bg-dark-bg-primary">
                <div
                  className={` activeIndicatorBG !w-[calc(50%-6px)] ${
                    searchData.selected === "hotel" ? "" : "!translate-x-[100%]"
                  } `}
                ></div>
                {tabName.map((eachTab, eachIndex, { text }: any) => (
                  <a
                    href="#"
                    key={eachIndex + 1 + ""}
                    onClick={() =>
                      !eachTab.disabled ? handleSelect(eachTab.value) : {}
                    }
                    className={` tab  tab-boxed flex items-center justify-center gap-1 py-2 pb-4 px-4 text-base font-normal cursor-pointer w-full  
                    ${
                      searchData.selected === "hotel" &&
                      eachTab.text === "Hotel"
                        ? "text-newText-100 dark:text-dark-text-primary !font-medium"
                        : searchData.selected === "flight" &&
                          eachTab.text === "Flight"
                        ? "text-newText-100  dark:text-dark-text-primary !font-medium  "
                        : "text-gray-500 "
                    }
                    `}
                  >
                    {eachTab.text === "Hotel" ? (
                      <Icon className="w-6 h-6" icon="solar:buildings-linear" />
                    ) : (
                      ""
                    )}
                    {eachTab.text === "Flight" ? (
                      <Icon className="w-6 h-6" icon="la:plane-departure" />
                    ) : (
                      ""
                    )}
                    {eachTab.text}
                  </a>
                ))}
              </div>
            </div>

            {searchData.selected === "hotel" ? (
              <SearchHotelComponents
                isHomePage={true}
                isLoading={isLoading}
                availableFilterType={availableFilterType}
                hotelAvailableList={searchAvailableList}
                getHotelListRequest={(value: any) =>
                  dispatch(homepageActions.getHotelListReq(value))
                }
                handleSearchStaticHotel={(value: any) =>
                  hotelSearchListActions.searchHotelByNameReq(value)
                }
              />
            ) : (
              <FlightComponent
                isHomePage={true}
                errors={errors}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                data={searchData.flightData}
                flightList={
                  searchData?.flightData?.from.length > 1 ||
                  searchData?.flightData?.to.length > 1
                    ? searchAvailableList
                    : noResult
                }
                handleChange={handleFlightChange}
                handleGuestData={handleGuestData}
                handleDateChange={handleDateChange}
                handleshowFlightList={handleshowFlightList}
                toggleData={toggleData}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroPage;
