import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";
import { ddlMapper } from "utils/helperFunctions";
import { stat } from "fs/promises";
import { toast } from "react-toastify";

export const initialState: ContainerState = {
  requesting: false,
  success: false,
  error: null,
  successMessage: "",
  hotelListData: {},
  hotelDataByCity: {},
  searchHotelListDown: [],
  isFetching: false,
  isFetchingPullHotel: false,
  recommendResp: {},
  recommendedList: [],
  isShareRecommRequesting: false,
  shareUrl: "",
  availableFilter: [],
};

const hotelSearchList = createSlice({
  name: "hotelSearchList",
  initialState,
  reducers: {
    searchHotelByNameReq(state: any) {
      state.isFetching = true;
      state.error = null;
      state.successMessage = "";
      state.success = false;
      state.searchHotelListDown = [];
      state.availableFilter = [];
    },
    searchHotelByNameSucc(state: any, action: any) {
      let mergedDropdown: any = [];
      let availableFilter: any = [];
      let pushAvailabelCategory: any = [];
      if (action.payload.data.cities) {
        const hotelsList =
          ddlMapper(
            action.payload.data.hotels,
            ["dataCat", "string", "string"],
            "Hotels",
            true
          ) || [];
        const citiesList =
          ddlMapper(
            action.payload.data.cities,
            ["dataCat", "string", "string"],
            "Cities",
            true
          ) || [];
        const locationList =
          ddlMapper(
            action.payload.data.locations,
            ["dataCat", "string", "string"],
            "Locations",
            true
          ) || [];
        mergedDropdown = [...citiesList, ...hotelsList, ...locationList];
        hotelsList.length && pushAvailabelCategory.push({ label: "Hotels" });
        citiesList.length && pushAvailabelCategory.push({ label: "Cities" });
        locationList.length &&
          pushAvailabelCategory.push({ label: "Locations" });
        pushAvailabelCategory.length > 1 &&
          pushAvailabelCategory.unshift({ label: "All" });
      } else {
        mergedDropdown = action.payload.data.map(
          (eachAirport: any, eachIndex: any) => ({
            ...eachAirport,
            dataCat: "Airport",
            text: `${eachAirport.city}, ${eachAirport.country_code} (${eachAirport.ita_code})`,
            value: eachAirport.ita_code,
            key: eachAirport.id,
          })
        );
      }
      state.availableFilter = pushAvailabelCategory;
      const defaultDropdown = [{ value: "Result not found", id: "" }];
      state.searchHotelListDown =
        mergedDropdown.length === 0 ? defaultDropdown : mergedDropdown;
      state.isFetching = false;
      state.error = null;
      state.success = true;
    },
    searchHotelByNameFail(state: any, action: { payload: { message: any } }) {
      const responseData = action.payload.message;
      state.successMessage = "";
      state.error = responseData;
      state.isFetching = false;
      state.success = false;
      state.searchHotelListDown = [];
    },
    getAllHotelListReq(
      state: any,
      action: { payload: { query: any; ids: any } }
    ) {
      //Dymanic hotel data
      state.requesting = true;
      state.error = null;
      state.successMessage = "";
      state.success = false;
      state.hotelListData = {};
    },
    getAllHotelListSucc(state: any, action: any) {
      state.hotelListData = action.payload.response || {};
      state.error = null;
      state.success = true;
      state.requesting = false;
    },
    getAllHotelListFail(state: any, action: { payload: { message: any } }) {
      const responseData = action.payload.message;
      // toast.error(action.payload.message);
      state.successMessage = "";
      state.error = responseData;
      state.requesting = false;
      state.success = false;
      state.hotelListData = {
        data: [],
      };
    },
    pullHotelDataReq(state, action) {
      //static hotel data
      // state.isFetchingPullHotel = true;
      state.error = null;
      state.successMessage = "";
      state.success = false;
      state.hotelDataByCity = {};
    },
    pullHotelDataSucc(state, action) {
      state.isFetchingPullHotel = false;
      state.error = null;
      state.successMessage = "";
      state.hotelDataByCity = {
        data: action.payload,
      };
    },
    pullHotelDataFail(state, action) {
      state.isFetchingPullHotel = false;
      state.error = action.payload;
      state.successMessage = "";
      state.hotelDataByCity = {};
    },
    getRecommendShareableCodeTypeRes(state: any, action) {
      state.shareUrl = "";
      state.recommendResp = {};
      state.isShareRecommRequesting = true;
    },
    getRecommendShareableCodeTypeSucc(state: any, action: any) {
      state.recommendResp = action.payload.data;
      state.shareUrl = action.payload?.data?.short_url_link;
      state.isShareRecommRequesting = false;
    },
    getRecommendShareableCodeTypeFail(state: any, action: any) {
      state.recommendResp = {};
      state.shareUrl = "";
      state.isShareRecommRequesting = false;
    },
    getRecommendedListReq(state: any, action: any) {
      state.recommendedList = [];
      state.isShareRecommRequesting = true;
    },
    getRecommendedListSucc(state: any, action: any) {
      state.recommendedList = [];
      state.isShareRecommRequesting = false;
    },
    getRecommendedListFail(state: any, action: any) {
      state.recommendedList = [];
      state.isShareRecommRequesting = false;
    },
    clearAllHotelState(state: any) {
      state.requesting = false;
      state.error = null;
      state.hotelListData = {};
      state.hotelDataByCity = {};
      state.success = false;
      state.isFetching = false;
      state.successMessage = "";
      state.recommendResp = {};
      state.recommendedList = [];
      state.isShareRecommRequesting = false;
      state.isFetchingPullHotel = false;
      state.searchHotelListDown = [];
      state.shareUrl = "";
    },
  },
});

export const {
  actions: hotelSearchListActions,
  reducer,
  name: sliceKey,
} = hotelSearchList;
