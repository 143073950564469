import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.homepage || initialState;

export const selectRequesting = createSelector(
  [selectDomain],
  (homepageState: { requesting: any }) => homepageState.requesting
);

export const selectSuccess = createSelector(
  [selectDomain],
  (homepageState: { success: any }) => homepageState.success
);

export const selectError = createSelector(
  [selectDomain],
  (homepageState: { error: any }) => homepageState.error
);

export const selectHotelList = createSelector(
  [selectDomain],
  (homepageState: { hotelListData: any }) => homepageState.hotelListData
);

export const selectSuccessMessage = createSelector(
  [selectDomain],
  (homepageState: { successMessage: any }) => homepageState.successMessage
);

export const selectLoading = createSelector(
  [selectDomain],
  (homepageState: { requesting: any }) => homepageState.requesting
);

export const selectAvailableFilterType = createSelector(
  [selectDomain],
  (homepageState: { availableFilter: any }) => homepageState.availableFilter
);

export const selectHotelRecentSearch = createSelector(
  [selectDomain],
  (homepageState: { recentHotelSearch: any }) => homepageState.recentHotelSearch
);

export const selectFlightRecentSearch = createSelector(
  [selectDomain],
  (homepageState: { recentFlightSearch: any }) => homepageState.recentFlightSearch
);