export const ageDropdown = () => {
    let ageList = [];
    for (let index = 0; index < 17; index++) {
        ageList.push({ text: index + 1, value: index + 1 });
    }
    return ageList;
};

export const classOptions = [
    {
        key: 1,
        text: 'Economy',
        value: 'E',
    },
    {
        key: 2,
        text: 'Business',
        value: 'B',
    },
    {
        key: 3,
        text: 'First',
        value: 'F',
    },
    {
        key: 4,
        text: 'Premium Economy',
        value: 'P',
    },
];

export const tabName = [
    { text: "Hotel", value: "hotel", disabled:false },
    { text: "Flight", value: "flight", disabled:false },
];